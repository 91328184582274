<template>
  <div class="content-container flex-row pb-0 pt-0">
    <div class="sidebar-container">
      <div class="menu">
        <ul class="menu-items">
          <li class="menu-title current">
            IOP Web Forms
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-up"
            >
              <polyline points="18 15 12 9 6 15"></polyline>
            </svg>
          </li>
          <li class="">content a</li>
          <li class="">content b</li>
        </ul>
      </div>
    </div>

    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>IOP Web Forms</h1>
              <!-- <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div> -->
            </div>
          </div>
        </section>
        <section>
          <div class="content-band">
            <p>
              IOP Web Forms are the State standards for using forms. We use
              Formio as our form solution, which gives the user the ability to
              create forms in a drag and drop fashion, send automated emails
              based on form completion and much more.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>
<script>
import SubFooter from "./SubFooter.vue";
export default {
  name: "IOPWebForms",
  components: {
    SubFooter,
  },
};
</script>
<style scoped>
.pagehead-title {
  max-width: none;
}
.subpage-content.iop-web-forms {
  height: 73vh;
}
.feather {
  position: absolute;
  right: 20px;
}
</style>
