<template>
  <footer>
    <div class="sub-footer gray-300">
      <div class="odx_secondary-footer__logo">
        <a
          href="https://innovateohio.gov/wps/portal/gov/innovate/platform"
          target="_blank"
          class="footer-bar__logo-link"
          aria-label="Innovate Ohio Platform, This link will open in a new window"
        >
          <img
            src="../assets/images/IOP - Powered - Dark.png"
            alt="Innovate Ohio Platform"
            width="216"
            class="iop-white-logo"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SubFooter",
};
</script>
<style>
footer{
  padding: 0 0!important;
}
</style>